import React, {useState} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const Subheader = ({ siteTitle }) => {
    const[menuCollapsed, toggleMenu] = useState(true)

    return (
        <div className="horizontal-header-nav" data-horizontal-header-nav="">
            <div className="horizontal-header-nav-wrapper">
                <nav className="navbar navbar-expand-lg navbar-uta-horizontal-nav" aria-label="Sitecore Design System navigation bar">

                    <div className="navbar-uta-horizontal-nav-header">
                            <h2 className="navbar-uta-horizontal-nav-title">Sitecore Design System</h2>

                        <button className={`navbar-toggler ${menuCollapsed ? 'collapsed' : null}`} type="button" data-toggle="collapse" data-target="#uta-college-navbar" aria-controls="uta-college-navbar" aria-expanded={menuCollapsed ? 'false' : 'true'} aria-label="Sitecore Design System navigation"
                        onClick={() => toggleMenu(!menuCollapsed)}></button>
                    </div>

                    <div className={`collapse navbar-collapse ${menuCollapsed ? '' : 'show'}`} id="uta-college-navbar">
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <Link to="/" className="nav-link" activeClassName="active"><span>Home</span></Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/components" className="nav-link" activeClassName="active" partiallyActive={true}><span>Components</span></Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/templates" className="nav-link" activeClassName="active" partiallyActive={true}><span>Templates</span></Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/style" className="nav-link" activeClassName="active" partiallyActive={true}><span>Style</span></Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/tutorials" className="nav-link" activeClassName="active" partiallyActive={true}><span>Tutorials</span></Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/updates" className="nav-link" activeClassName="active" partiallyActive={true}><span>Updates</span></Link>
                            </li>

                            <li className="nav-item nav-item-callout">
                                <a href="https://blog.uta.edu/redesign" className="nav-link nav-link-callout"><span>About Web Mod</span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

Subheader.propTypes = {
  siteTitle: PropTypes.string,
}

Subheader.defaultProps = {
  siteTitle: ``,
}

export default Subheader
